<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Notifications</div>
                    <div class="card-body">
                        <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="toast-header">
                                <strong class="mr-auto">E-Courier</strong>
                                <small>11 mins ago</small>
                                <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="toast-body">
                                Hello, world! This is a toast message.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Translucent Notifications</div>
                    <div class="card-body">
                        <div class="bg-dark p-4">
                            <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
                                <div class="toast-header">
                                    <strong class="mr-auto">E-Courier</strong>
                                    <small class="text-muted">11 mins ago</small>
                                    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="toast-body">
                                    Hello, world! This is a toast message.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Stacking Notifications</div>
                    <div class="card-body">
                        <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="toast-header">
                                <strong class="mr-auto">E-Courier</strong>
                                <small class="text-muted">just now</small>
                                <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="toast-body">
                                See? Just like this.
                            </div>
                        </div>
                        <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="toast-header">
                                <strong class="mr-auto">E-Courier</strong>
                                <small class="text-muted">2 seconds ago</small>
                                <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="toast-body">
                                Heads up, toasts will stack automatically
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Placement</div>
                    <div class="card-body">
                        <div aria-live="polite" aria-atomic="true" style="position: relative; min-height: 200px;">
                            <div class="toast fade show" style="position: absolute; top: 0; right: 0;">
                                <div class="toast-header">
                                    <strong class="mr-auto">E-Courier</strong>
                                    <small>11 mins ago</small>
                                    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="toast-body">
                                    Hello, world! This is a toast message.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Usage</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div class="text-center">
                                    <p>Default</p>
                                    <button type="button" class="btn btn-light" @click="makeToast">Click Me</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="text-center">
                                    <p>Primary</p>
                                    <button type="button" class="btn btn-primary" @click="makeToast('primary', 'b-toaster-top-right', true)">Click Me</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="text-center">
                                    <p>Secondary</p>
                                    <button type="button" class="btn btn-secondary" @click="makeToast('secondary', 'b-toaster-top-right', true)">Click Me</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="text-center">
                                    <p>Warning</p>
                                    <button type="button" class="btn btn-warning" @click="makeToast('warning', 'b-toaster-top-right', true)">Click Me</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="text-center">
                                    <p>Danger</p>
                                    <button type="button" class="btn btn-danger" @click="makeToast('danger', 'b-toaster-top-right', true)">Click Me</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="text-center">
                                    <p>Success</p>
                                    <button type="button" class="btn btn-success" @click="makeToast('success', 'b-toaster-top-right', true)">Click Me</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="text-center">
                                    <p>Info</p>
                                    <button type="button" class="btn btn-info" @click="makeToast('info', 'b-toaster-top-right', true)">Click Me</button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5 row-cols-4">
                            <div class="col mb-3">
                                <div class="text-center">
                                    <p>Top Right (Default)</p>
                                    <button type="button" class="btn btn-secondary" @click="makeToast">Click Me</button>
                                </div>
                            </div>
                            <div class="col mb-3">
                                <div class="text-center">
                                    <p>Top Left</p>
                                    <button type="button" class="btn btn-secondary" @click="makeToast(null, 'b-toaster-top-left')">Click Me</button>
                                </div>
                            </div>
                            <div class="col mb-3">
                                <div class="text-center">
                                    <p>Top Center</p>
                                    <button type="button" class="btn btn-secondary" @click="makeToast(null, 'b-toaster-top-center')">Click Me</button>
                                </div>
                            </div>
                            <div class="col mb-3">
                                <div class="text-center">
                                    <p>Top Full</p>
                                    <button type="button" class="btn btn-secondary" @click="makeToast(null, 'b-toaster-top-full', true)">Click Me</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="text-center">
                                    <p>Bottom Right</p>
                                    <button type="button" class="btn btn-secondary" @click="makeToast(null, 'b-toaster-bottom-right', true)">Click Me</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="text-center">
                                    <p>Bottom Left</p>
                                    <button type="button" class="btn btn-secondary" @click="makeToast(null, 'b-toaster-bottom-left', true)">Click Me</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="text-center">
                                    <p>Bottom Center</p>
                                    <button type="button" class="btn btn-secondary" @click="makeToast(null, 'b-toaster-bottom-center', true)">Click Me</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="text-center">
                                    <p>Bottom Full</p>
                                    <button type="button" class="btn btn-secondary" @click="makeToast(null, 'b-toaster-bottom-full', true)">Click Me</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Notifications',
        data() {
            return {
                count: 0
            }
        },
        methods: {
            makeToast(variant = null, toaster = 'b-toaster-top-right', append = false) {
                this.count++
                this.$bvToast.toast(`This is toast number ${this.count}`, {
                    title: 'E-Courier Toast',
                    variant: variant,
                    toaster: toaster,
                    autoHideDelay: 5000,
                    appendToast: append
                })
            }
        }
    }
</script>
